import api from "../../config/api";
import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import swal from "sweetalert";
// javascript plugin used to create scrollbars on windows

function ListSubCategoriesLine({ adSubCategories, getAdSubCategories }) {
  const [isLoading, setLoading] = useState([false, 0]);

  const deleteCategory = async (id) => {
    setLoading([true, id]);
    await api
      .delete(`adSubCategory/${id}`)
      .then((resp) => {
        getAdSubCategories();
        swal("Sucesso!", "Categoria deletada com sucesso!", "success");
      })
      .finally(() => {
        setLoading([false, 0]);
      });
  };

  return (
    <tbody className="">
      {adSubCategories.length ? (
        <>
          {adSubCategories.map((adSubCategory, index) => (
            <tr key={adSubCategory._id} className="">
              <td className="text-center ">
                {adSubCategory.icon ? (
                  <>
                    <div className="overflow-hidden" style={{ height: "40px", width: "40px", margin: "0 auto" }}>
                      <img src={adSubCategory.icon} alt={adSubCategory.title.en}></img>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </td>
              <td className="text-center">{adSubCategory.adCategory.title.en}</td>
              <td className="text-center">{adSubCategory.title.pt}</td>
              <td className="text-center">{adSubCategory.title.en}</td>
              <td className="text-center">{adSubCategory.title.es}</td>
              <td className="text-center">{adSubCategory.title.it}</td>
              <td className="text-center">{adSubCategory.title.fr}</td>
              <td className="text-center">{adSubCategory.title.ar}</td>
              <td className="text-center">
                <a href={`./edit-sub-category/${adSubCategory._id}`} className="text-white">
                  <Button className="m-1 btn-success">
                    <i className="nc-icon nc-settings-gear-65" />
                  </Button>
                </a>
                <Button className="m-1 btn-danger" onClick={() => deleteCategory(adSubCategory._id)}>
                  {isLoading[0] && isLoading[1] === adSubCategory._id ? (
                    <Spinner size={"sm"} animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <i className="nc-icon nc-simple-remove" />
                  )}
                </Button>
              </td>
            </tr>
          ))}
        </>
      ) : (
        <tr key={0}>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">
            <Button className="m-1 btn-success">
              <i className="nc-icon nc-settings-gear-65" />
            </Button>
            <Button className="m-1 btn-danger">
              <i className="nc-icon nc-simple-remove" />
            </Button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

export default ListSubCategoriesLine;
