import api from "../../config/api";
import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import swal from "sweetalert";
// javascript plugin used to create scrollbars on windows

function ListBanners({ banners, getBanners }) {
  const [isLoading, setLoading] = useState([false, 0]);

  const deleteBanner = async (id) => {
    setLoading([true, id]);
    await api
      .delete(`banner/${id}`)
      .then((resp) => {
        getBanners();
        swal("Sucesso!", "Banner deletado com sucesso!", "success");
      })
      .finally(() => {
        setLoading([false, 0]);
      });
  };

  return (
    <tbody className="">
      {banners.length ? (
        <>
          {banners.map((banner, index) => (
            <tr key={banner._id}>
              <td className="">
                {banner.url ? (
                  <>
                    <div className="col-lg-6 " style={{ margin: "0 auto", maxWidth: "100%" }}>
                      <a href={banner.url} target="_blank" rel="noreferrer">
                        <img src={banner.url} alt={banner.link}></img>
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </td>
              <td className="text-center col-lg-1">{banner?.adCategory?.title?.pt}</td>
              <td className="text-center col-lg-2">{banner.main === true ? "Sim" : "Não"}</td>
              <td className="text-center col-lg-1">{banner.positioning}</td>
              <td className="text-center col-lg-4 ">
                <a
                  className="col-lg-4"
                  href={banner.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textOverflow: "ellipsis",
                    maxWidth: "250px",
                    display: "block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {banner.link}
                </a>
              </td>

              <td className="text-center">
                <a href={`./edit-banner/${banner._id}`} className="text-white">
                  <Button className="m-1 btn-success">
                    <i className="nc-icon nc-settings-gear-65" />
                  </Button>
                </a>
                <Button className="m-1 btn-danger" onClick={() => deleteBanner(banner._id)}>
                  {isLoading[0] && isLoading[1] === banner._id ? (
                    <Spinner size={"sm"} animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <i className="nc-icon nc-simple-remove" />
                  )}
                </Button>
              </td>
            </tr>
          ))}
        </>
      ) : (
        <tr key={0}>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">
            <Button className="m-1 btn-success">
              <i className="nc-icon nc-settings-gear-65" />
            </Button>
            <Button className="m-1 btn-danger">
              <i className="nc-icon nc-simple-remove" />
            </Button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

export default ListBanners;
