import api from "../config/api";
import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, FormGroup, Form, Row, Col, Spinner } from "reactstrap";
import swal from "sweetalert";
import { useParams } from "react-router-dom";

function EditBanner() {
	const { id } = useParams();
	const [img, setImg] = useState({});
	const [newImage, setNewImage] = useState("");
	const [link, setLink] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [numOrder, setNumOrder] = useState([]);
	const [order, setOrder] = useState(0);
	const [banner, setBanner] = useState({});
	const [adCategories, setAdCategories] = useState([]);
	const [adCategory, setAdCategory] = useState("");
	const [main, setMain] = useState("");

	const getBanner = async () => {
		await api.get(`banner/${id}`).then((resp) => {
			setBanner(() => resp?.data);
			setLink(() => resp.data?.link);
			setOrder(() => resp.data?.positioning);
			setMain(() => resp.data?.main);
			setAdCategory(() => resp.data?.adCategory?._id);
		});
	};

	const editBanner = async (e) => {
		setLoading(true);
		e.preventDefault();
		const config = {
			headers: {
				"Content-Type": `multipart/form-data`,
			},
		};

		const form = new FormData();
		form.append("file", img);
		form.append("positioning", order);
		form.append("link", link);
		adCategory && form.append("adCategory", adCategory);
		form.append("main", main);

		await api
			.put(`/banner/${id}`, form, config)
			.then((resp) => {
				swal("Sucesso!", "Banner criado com sucesso!", "success");
				setTimeout(() => {
					window.location = "/admin/leads";
				}, 1000);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getBanners = async () => {
		try {
			await api.get(`/banner/`).then((resp) => {
				let array = [];
				if (resp.data.length) {
					resp.data.forEach((element, index) => {
						array.push(index);
					});
					setNumOrder(array);
				}
			});
		} catch (error) {
			console.log("getBanners error", error.message);
		}
	};

	const getAdCategories = async () => {
		try {
			await api.get(`/adCategory/`).then((resp) => {
				setAdCategories(() => resp.data);
			});
		} catch (error) {
			console.log("getAdCategories error", error.message);
		}
	};

	const changeImg = (file) => {
		setNewImage(URL.createObjectURL(file));
		setImg(file);
	};
	useEffect(() => {
		getBanners();
		getAdCategories();
	}, []);

	useEffect(() => {
		getBanner();
	}, [id]);

	return (
		<>
			<div className='content'>
				<Row className='justify-content-center'>
					<Col md='8'>
						<Card className='card-user'>
							<CardHeader>
								<CardTitle tag='h5'>Cadastrar banner</CardTitle>
							</CardHeader>
							<CardBody>
								<Form>
									<Row>
										<Col md='8'>
											<FormGroup>
												<div className='d-flex align-items-center'>
													{newImage ? (
														<div className='overflow-hidden ' style={{ height: "auto", width: "200px" }}>
															<img src={newImage}></img>
														</div>
													) : (
														<div className='overflow-hidden ' style={{ height: "auto", width: "200px" }}>
															<img src={banner.url}></img>
														</div>
													)}
													<div className='' style={{ marginLeft: "20px" }}>
														<label>Banner* (1920px x 800px)</label>
														<input className='w-100' type='file' required onChange={(e) => changeImg(e.target.files[0])} />
													</div>
												</div>
											</FormGroup>
										</Col>
										<Col md='4'>
											<FormGroup>
												<label>Posição*</label>
												<select className='w-100' defaultValue={banner.positioning} type='text' required onChange={(e) => setOrder(() => parseInt(e.target.value))}>
													{numOrder.length ? (
														<>
															{numOrder.map((value, index) => (
																<option key={index + value} selected={value === banner.positioning} value={value}>
																	{value}
																</option>
															))}
														</>
													) : (
														<></>
													)}
												</select>
											</FormGroup>
										</Col>
										<Col md='12'>
											<FormGroup>
												<label>URL</label>
												<input className='w-100' defaultValue={banner.link} type='text' placeholder='https://jaspiapp.com' onChange={(e) => setLink(() => e.target.value)} required />
											</FormGroup>
										</Col>
										<Col md='8'>
											<FormGroup>
												<label>Categoria*</label>
												<select className='w-100' defaultValue={banner?.adCategory} type='text' required onChange={(e) => setAdCategory(() => e.target.value)}>
													<option value={""}></option>
													{adCategories.length ? (
														<>
															{adCategories.map((value) => (
																<option key={value._id} selected={value._id === banner?.adCategory?._id} value={value._id}>
																	{value.title.pt}
																</option>
															))}
														</>
													) : (
														<></>
													)}
												</select>
											</FormGroup>
										</Col>
										<Col md='4'>
											<FormGroup>
												<label>Banner Principal</label>
												<input className='w-100' type='checkbox' placeholder='https://jaspiapp.com' onChange={(e) => setMain(e.target.checked)} checked={main} required />
											</FormGroup>
										</Col>
										<Col md='12'>
											<Button block color='primary' onClick={(e) => editBanner(e)}>
												{isLoading ? (
													<Spinner size={"sm"} animation='border' role='status'>
														<span className='visually-hidden'>Loading...</span>
													</Spinner>
												) : (
													"Salvar"
												)}
											</Button>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default EditBanner;
