import api from "../config/api";
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";

function RegisterCategory() {
  const [category, setCategory] = useState({
    ar: "",
    en: "",
    es: "",
    fr: "",
    it: "",
    pt: "",
  });
  const [img, setImg] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [numOrder, setNumOrder] = useState([]);
  const [order, setOrder] = useState(0);

  const handleCategoryChange = (event) => {
    setCategory((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const createCategory = async (e) => {
    setLoading(true);
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    };
    for (const key in category) {
      const value = category[key];
      if (value === "" || value === undefined) {
        setLoading(false);
        return swal("Erro!", "Todos os campos devem ser preenchidos", "error");
      }
    }
    if (!img.name) {
      setLoading(false);
      return swal("Erro!", "Uma imagem deve ser enviada", "error");
    }
    const form = new FormData();
    form.append("file", img);
    form.append("title", JSON.stringify(category));
    form.append("order", order);

    await api
      .post(`/adCategory/`, form, config)
      .then((resp) => {
        swal("Sucesso!", "Categoria criada com sucesso!", "success");
        setTimeout(() => {
          window.location = "/admin/categorias";
        }, 1000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAdCategories = async () => {
    try {
      await api.get(`/adCategory/`).then((resp) => {
        let array = [];
        if (resp.data.length) {
          resp.data.forEach((element, index) => {
            array.push(index);
          });
          array.push(array[array.length - 1] + 1);
          setNumOrder(array);
          setOrder(array[array.length - 1]);
        } else {
          array.push(0);
          setNumOrder(array);
          setOrder(0);
        }
      });
    } catch (error) {
      console.log("getAdCategories error", error.message);
    }
  };

  useEffect(() => {
    getAdCategories();
  }, []);

  return (
    <>
      <div className="content">
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Cadastrar categoria</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Português)*</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          required
                          name="pt"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Inglês)*</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          required
                          name="en"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Árabe)*</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          required
                          name="ar"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Espanhol)*</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          required
                          name="es"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Italiano)*</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          required
                          name="it"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Francês)*</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          required
                          name="fr"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <FormGroup>
                        <label>Icone da categoria* (512px x 512px)</label>
                        <input
                          className="w-100"
                          defaultValue={""}
                          type="file"
                          required
                          onChange={(e) => setImg(e.target.files[0])}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Posição*</label>
                        <select
                          className="w-100"
                          defaultValue={""}
                          type="text"
                          onChange={(e) => setOrder(() => parseInt(e.target.value))}
                          required
                          name="order"
                        >
                          {numOrder.length ? (
                            <>
                              {numOrder.map((value) => (
                                <option
                                  key={value + value}
                                  selected={value === numOrder[numOrder.length - 1]}
                                  value={value}
                                >
                                  {value}
                                </option>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button block color="primary" onClick={(e) => createCategory(e)}>
                        {isLoading ? (
                          <Spinner size={"sm"} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Salvar"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RegisterCategory;
