import axios from "axios";
// import { getToken } from "./auth";

const api = axios.create({
  baseURL: "https://api.jaspiapp.com/",
  // baseURL: "http://localhost:3333/",
});

// api.interceptors.request.use(async (config) => {
//   // const token = getToken();
//   let token = false;
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

export default api;
