import React, { useState } from "react";
// reactstrap components
import { FormGroup, Form, Label, Input, Button } from "reactstrap";
import swal from "sweetalert";

function Login() {
  const [password, setPassword] = useState("");

  const validade = (e) => {
    e.preventDefault();
    if (password !== process.env.REACT_APP_LOGIN_PASSWORD) {
      return swal("Error", "Senha inválida", "error");
    }
    if (password === process.env.REACT_APP_LOGIN_PASSWORD) localStorage.setItem("@JaspiCMS:password", password);
    swal("Sucesso", "Login realizado", "success");
    setTimeout(() => {
      return (window.location = "/admin/leads");
    }, 1000);
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-auto h-100">
        <div className="Form-login">
          <Form className="form-login-react" onSubmit={(e) => validade(e)}>
            <FormGroup>
              <Label className="label-login" for="examplePassword">
                Password
              </Label>
              <Input
                type="password"
                name="password"
                id="examplePassword"
                placeholder="********"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <Button onClick={(e) => validade(e)}>Submit</Button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
