import TableList from "views/Tables.js";
import ListCategories from "views/ListCategories.js";
import RegisterCategory from "views/RegisterCategory.js";
import RegisterBanner from "views/RegisterBanner.js";
import EditCategory from "views/EditCategory.js";
import EditBanner from "views/EditBanner";
import ListSubCategories from "views/ListSubCategories";
import Notification from "views/Notification";
import EditSubCategory from "views/EditSubCategory";
import RegisterSubCategory from "views/RegisterSubCategory";

var routes = [
	{
		path: "/leads",
		name: "Banners",
		icon: "nc-icon nc-trophy",
		component: TableList,
		layout: "/admin",
	},
	{
		path: "/categorias",
		name: "Categorias",
		icon: "nc-icon nc-tile-56",
		component: ListCategories,
		layout: "/admin",
	},
	{
		path: "/sub-categorias",
		name: "Sub Categorias",
		icon: "nc-icon nc-bookmark-2",
		component: ListSubCategories,
		layout: "/admin",
	},
	{
		path: "/notificacoes",
		name: "Notificações",
		icon: "nc-icon nc-bell-55",
		component: Notification,
		layout: "/admin",
	},
	{
		path: "/edit-sub-category/:id",
		name: "Editar sub categoria",
		icon: "nc-icon nc-single-02",
		component: EditSubCategory,
		layout: "/admin",
	},
	{
		path: "/add-sub-category",
		name: "Editar sub categoria",
		icon: "nc-icon nc-single-02",
		component: RegisterSubCategory,
		layout: "/admin",
	},
	{
		path: "/add-category",
		name: "Adicionar categoria",
		icon: "nc-icon nc-single-02",
		component: RegisterCategory,
		layout: "/admin",
	},
	{
		path: "/edit-category/:id",
		name: "Editar categoria",
		icon: "nc-icon nc-single-02",
		component: EditCategory,
		layout: "/admin",
	},
	{
		path: "/add-banner",
		name: "Adicionar banner",
		icon: "nc-icon nc-single-02",
		component: RegisterBanner,
		layout: "/admin",
	},
	{
		path: "/edit-banner/:id",
		name: "Editar banner",
		icon: "nc-icon nc-single-02",
		component: EditBanner,
		layout: "/admin",
	},
];
export default routes;
