import api from "../config/api";
import React, { useState, useEffect } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col } from "reactstrap";
import ListBanners from "components/ListBanners/ListBanners";

function Tables() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      await api.get(`/banner/`).then((resp) => {
        setBanners(() => resp.data);
      });
    } catch (error) {
      console.log("getBanners error", error.message);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Banners{" "}
                  <a href="./add-banner" className=" px-3 rounded m-1 btn-primary float-right">
                    <small>
                      {" "}
                      Adicionar <i className="nc-icon nc-simple-add" />
                    </small>
                  </a>
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center col-lg-4">Imagem</th>
                      <th className="text-center col-lg-1">Categoria</th>
                      <th className="text-center col-lg-2">Banner Principal</th>
                      <th className="text-center col-lg-1">Posição</th>
                      <th className="text-center col-lg-4">URL</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <ListBanners {...{ banners, getBanners }} />
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
