import api from "../config/api";
import React, { useState, useEffect } from "react";
import ListCategoriesLine from "../components/ListCategories/ListCategoriesLine";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button } from "reactstrap";
import ListSubCategoriesLine from "components/ListSubCategories/ListSubCategoriesLine";

function ListSubCategories() {
  const [adSubCategories, setAdSubCategories] = useState([]);

  useEffect(() => {
    getAdSubCategories();
  }, []);

  const getAdSubCategories = async () => {
    try {
      await api.get(`/adSubCategory/`).then((resp) => {
        setAdSubCategories(() => resp.data);
      });
    } catch (error) {
      console.log("getAdSubCategories error", error.message);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Sub categorias
                  <a href="./add-sub-category" className=" px-3 rounded m-1 btn-primary float-right">
                    <small>
                      Adicionar <i className="nc-icon nc-simple-add" />
                    </small>
                  </a>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Icone</th>
                      <th className="text-center">Categoria</th>
                      <th className="text-center">Nome - BR</th>
                      <th className="text-center">Nome - EN</th>
                      <th className="text-center">Nome - ES</th>
                      <th className="text-center">Nome - IT</th>
                      <th className="text-center">Nome - FR</th>
                      <th className="text-center">Nome - AR</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <ListSubCategoriesLine {...{ adSubCategories, getAdSubCategories }} />
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ListSubCategories;
