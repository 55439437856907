import api from "../../config/api";
import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import swal from "sweetalert";
// javascript plugin used to create scrollbars on windows

function ListNotifications({ notifications, getNotifications }) {
  const [isLoading, setLoading] = useState([false, 0]);

  const deleteNotification = async (id) => {
    setLoading([true, id]);
    await api
      .delete(`notifications/${id}`)
      .then((resp) => {
        getNotifications();
        swal("Sucesso!", "Notificação deletada com sucesso!", "success");
      })
      .finally(() => {
        setLoading([false, 0]);
      });
  };

  function formatToLocalDateTime(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false, // Use 24-hour format
    };
    const formattedDate = date.toLocaleString(undefined, options);

    return formattedDate;
  }

  return (
    <tbody className="">
      {notifications.length ? (
        <>
          {notifications.map((notification, index) => (
            <tr key={notification._id}>
              <td className="text-center col-lg-2">{formatToLocalDateTime(notification?.dateToPush)}</td>
              <td className="text-center col-lg-3">{notification?.title}</td>
              <td className="text-center col-lg-5">{notification?.msg}</td>
              <td className="text-center col-lg-1">{notification?.sent === true ? "Sim" : "Não"}</td>
              <td className="text-center">
                <Button className="m-1 btn-danger" onClick={() => deleteNotification(notification._id)}>
                  {isLoading[0] && isLoading[1] === notification._id ? (
                    <Spinner size={"sm"} animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <i className="nc-icon nc-simple-remove" />
                  )}
                </Button>
              </td>
            </tr>
          ))}
        </>
      ) : (
        <tr key={0}>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          <td className="text-center">
            <Button className="m-1 btn-danger">
              <i className="nc-icon nc-simple-remove" />
            </Button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

export default ListNotifications;
