import api from "../config/api";
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

function EditCategory() {
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const [img, setImg] = useState({});
  const [newImage, setNewImage] = useState("");
  const [numOrder, setNumOrder] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState({
    ar: "",
    en: "",
    es: "",
    fr: "",
    it: "",
    pt: "",
  });
  const [categoryOrder, setCategoryOrder] = useState(0);

  const handleCategoryChange = (event) => {
    setCategoryTitle((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    getCategory();
    getAdCategories();
  }, []);

  const getCategory = async () => {
    await api.get(`adCategory/${id}`).then((resp) => {
      setCategory(() => resp.data);
      setCategoryOrder(() => resp.data.order);
      setCategoryTitle((value) => ({ ...value, ...resp.data.title }));
    });
  };

  const getAdCategories = async () => {
    try {
      await api.get(`/adCategory/`).then((resp) => {
        let array = [];
        resp.data.forEach((element, index) => {
          array.push(index);
        });
        setNumOrder(array);
      });
    } catch (error) {
      console.log("getAdCategories error", error.message);
    }
  };

  const editCategory = async (e) => {
    setLoading(true);
    e.preventDefault();

    for (const key in categoryTitle) {
      const value = categoryTitle[key];
      if (value === "" || value === undefined) {
        setLoading(false);
        return swal("Erro!", "Todos os campos devem ser preenchidos", "error");
      }
    }

    const config = {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    };
    const form = new FormData();
    form.append("file", img);
    form.append("title", JSON.stringify(categoryTitle));
    form.append("order", categoryOrder);
    await api
      .put(`/adCategory/${id}`, form, config)
      .then((resp) => {
        getCategory();
        swal("Sucesso!", "Categoria editada com sucesso!", "success");
        setTimeout(() => {
          window.location = "/admin/categorias";
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    window.location = "/admin/categorias";
  };

  const changeImg = (file) => {
    setNewImage(URL.createObjectURL(file));
    setImg(file);
  };

  return (
    <>
      <div className="content">
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Editar categoria</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Português)*</label>
                        <input
                          className="w-100"
                          defaultValue={category?.title?.pt}
                          value={categoryTitle.pt}
                          type="text"
                          required
                          name="pt"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Inglês)*</label>
                        <input
                          className="w-100"
                          defaultValue={category?.title?.en}
                          value={categoryTitle.en}
                          type="text"
                          required
                          name="en"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Árabe)*</label>
                        <input
                          className="w-100"
                          value={categoryTitle.ar}
                          defaultValue={category?.title?.ar}
                          type="text"
                          required
                          name="ar"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Espanhol)*</label>
                        <input
                          className="w-100"
                          defaultValue={category?.title?.es}
                          value={categoryTitle.es}
                          type="text"
                          required
                          name="es"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Italiano)*</label>
                        <input
                          className="w-100"
                          defaultValue={category?.title?.it}
                          value={categoryTitle.it}
                          type="text"
                          required
                          name="it"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nome da categoria - (Francês)*</label>
                        <input
                          className="w-100"
                          defaultValue={category?.title?.fr}
                          value={categoryTitle.fr}
                          type="text"
                          required
                          name="fr"
                          onChange={(e) => handleCategoryChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <FormGroup>
                        <div className="d-flex align-items-center">
                          {newImage ? (
                            <div className="overflow-hidden " style={{ height: "40px", width: "40px" }}>
                              <img src={newImage}></img>
                            </div>
                          ) : (
                            <div className="overflow-hidden " style={{ height: "40px", width: "40px" }}>
                              <img src={category.icon}></img>
                            </div>
                          )}
                          <div className="" style={{ marginLeft: "20px" }}>
                            <label>Icone da categoria* (512px x 512px)</label>
                            <input
                              className="w-100"
                              type="file"
                              required
                              onChange={(e) => changeImg(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Posição*</label>
                        <select
                          className="w-100"
                          defaultValue={category?.order}
                          type="text"
                          required
                          name="order"
                          onChange={(e) => setCategoryOrder(e.target.value)}
                        >
                          {numOrder.length ? (
                            <>
                              {numOrder.map((value) => (
                                <option key={category._id + value} selected={value === category.order} value={value}>
                                  {value}
                                </option>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button block color="primary" onClick={(e) => editCategory(e)}>
                        {isLoading ? (
                          <Spinner size={"sm"} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Salvar"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditCategory;
