import Login from "views/Login";

var routesLogin = [
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-tile-56",
    component: Login,
    layout: "/admin",
  },
];
export default routesLogin;
