import React from "react";
import "./styles.css";

function Toggle({
  text,
  size = "default",
  checked,
  disabled,
  onChange,
  offstyle = "btn-danger",
  onstyle = "btn-success",
  name,
}) {
  const checkedVerify = JSON.parse(checked);
  const displayStyle = checkedVerify ? onstyle : offstyle;
  return (
    <>
      <label className="d-flex align-items-center">
        <span className={`${size} switch-wrapper`}>
          <input
            type="checkbox"
            checked={checkedVerify}
            disabled={disabled}
            name={name}
            value={!checkedVerify}
            onChange={(e) => onChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
        <span className="switch-label">{text}</span>
      </label>
    </>
  );
}

export default Toggle;
