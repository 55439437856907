import Toggle from "components/Toggle/Toggle";
import api from "../config/api";
import React, { useState, useEffect, useMemo } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
  Spinner,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import swal from "sweetalert";
import ListNotifications from "components/ListNotifications/ListNotifications";
import AsyncSelect from "react-select/async";
//! DEVE SER RETIRADO
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InN0YXR1cyI6ImFjdGl2ZSIsImNoYXQiOnRydWUsIm15RmF2b3JpdGVzIjpbXSwibG9jYWxpemF0aW9uIjpbeyJkZXNjcmlwdGlvbiI6IkN1cml0aWJhLCBTdGF0ZSBvZiBQYXJhbsOhLCBCcmF6aWwiLCJsYXRpdHVkZSI6LTI1LjQzNzIzODIsImxvbmdpdHVkZSI6LTQ5LjI2OTk3MjcsImNvdW50cnkiOiJCcmF6aWwifV0sIl9pZCI6IjYzNDU1ZDQ4NmJhNzYzMDAyMmZiMTdlNSIsInJvbGUiOiJCdXllciBhbmQgU2VsbGVyIiwibmFtZSI6IkZlbGlwZSIsInN1cm5hbWUiOiJTY3plbnNueSAiLCJlbWFpbCI6ImZlbGlwZXNkbEBvdXRsb29rLmNvbSIsInBhc3N3b3JkIjoiJDJiJDEwJEpvUlJmcmN2VFFqaTJmd3dqWkRzbk8zbzRhU1BWRFpwc1ZZdmRaN0R0S25YajdUZUZqZktHIiwiY3JlYXRlZEF0IjoiMjAyMi0xMC0xMVQxMjoxMDo0OC40ODdaIiwidXBkYXRlZEF0IjoiMjAyMy0wOC0wNFQyMDoxMDowNS44MTZaIiwiX192IjowLCJhdmF0YXIiOiJodHRwczovL2phc3BpYXBwLnMzLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tLzdhMTI2ZDllYzIyMzNjYjU0ZDA4N2UxYTUxMjYxOWI4LTVkNTcwMjBlLTcxMzUtNGM4Yy04MGIzLWU1ZWQ4Y2JhODZmYS5qcGVnIiwiYXZhdGFyS2V5IjoiN2ExMjZkOWVjMjIzM2NiNTRkMDg3ZTFhNTEyNjE5YjgtNWQ1NzAyMGUtNzEzNS00YzhjLTgwYjMtZTVlZDhjYmE4NmZhLmpwZWciLCJub3RpZmljYXRpb25Ub2tlbiI6IkV4cG9uZW50UHVzaFRva2VuW2tuY252ZEhPMXRScURoT2pjOV9nWDFdIiwic3RvcmUiOnsiY29udGFjdCI6eyJ3aGF0c2FwcCI6IlNvbWV0aGluZyIsImluc3RhZ3JhbSI6IlNvbWV0aGluZyIsImZhY2Vib29rIjoiU29tZXRoaW5nIiwid2Vic2l0ZSI6IlNvbWV0aGluZyIsImVtYWlsIjoiU29tZXRoaW5nIiwicGhvbmUiOiI0MTk5OTk5OTk5OSJ9LCJsb2dvIjp7InVybCI6Imh0dHBzOi8vc3RvcmFnZS5nb29nbGVhcGlzLmNvbS9hdXRoLWphc3BpLmFwcHNwb3QuY29tL2E5NzYxOGY5ZjBjYzdhODgxMDhhYWNhMDE4NTU1YmYybG9nby4iLCJrZXkiOiJhOTc2MThmOWYwY2M3YTg4MTA4YWFjYTAxODU1NWJmMmxvZ28uIn0sImJhbm5lciI6eyJ1cmwiOiJodHRwczovL3N0b3JhZ2UuZ29vZ2xlYXBpcy5jb20vYXV0aC1qYXNwaS5hcHBzcG90LmNvbS9hOTc2MThmOWYwY2M3YTg4MTA4YWFjYTAxODU1NWJmMmJhbm5lci4iLCJrZXkiOiJhOTc2MThmOWYwY2M3YTg4MTA4YWFjYTAxODU1NWJmMmJhbm5lci4ifSwiX2lkIjoiNjQ4NjI3ZDUyMjNmZmMwMzk4YzFlMDRhIiwib3BlbiI6W10sIm5hbWUiOiJGZWxpcGUiLCJhYm91dCI6IlNvbWV0aGluZyIsInNlcnZpY2VzIjoiU29tZXRoaW5nIiwiY2F0ZWdvcnkiOiI2M2VjMThmZTgyZGFkOTFkYWNlNDQwZTkiLCJyZXZpZXdDb3VudCI6My44M319LCJpYXQiOjE2OTI0ODQ0Mjl9.zxVnnwKQCqOuFL2nImiXH_fr5aGHFolGySIrd_ijwwc";

function Notification() {
  const [titulo, setTitulo] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [pais, setPais] = useState("");
  const [toggle, setToggle] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [isLoading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [link, setLink] = useState("");
  const hoursList = [
    { id: 1, value: "00:00" },
    { id: 2, value: "01:00" },
    { id: 3, value: "02:00" },
    { id: 4, value: "03:00" },
    { id: 5, value: "04:00" },
    { id: 6, value: "05:00" },
    { id: 7, value: "06:00" },
    { id: 8, value: "07:00" },
    { id: 9, value: "08:00" },
    { id: 10, value: "09:00" },
    { id: 11, value: "10:00" },
    { id: 12, value: "11:00" },
    { id: 13, value: "12:00" },
    { id: 14, value: "13:00" },
    { id: 15, value: "14:00" },
    { id: 16, value: "15:00" },
    { id: 17, value: "16:00" },
    { id: 18, value: "17:00" },
    { id: 19, value: "18:00" },
    { id: 20, value: "19:00" },
    { id: 21, value: "20:00" },
    { id: 22, value: "21:00" },
    { id: 23, value: "22:00" },
    { id: 24, value: "23:00" },
  ];

  const formatDate = () => {
    const [year, month, day] = date.split("-");
    const [hours, minutes] = time.split(":");
    let newDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
    //newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60);
    return newDate;
  };

  const sendNotification = async () => {
    setLoading(true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    let notificacao = {
      title: titulo,
      msg: mensagem,
      url: { page: "Single Ad", id: link },
    };
    if (toggle && !date) {
      return swal("Erro!", "Selecione uma data", "error");
    }
    if (toggle && !time) {
      return swal("Erro!", "Selecione uma opção de hora", "error");
    }
    if (toggle && date) {
      notificacao.dateToPush = formatDate();
      return await api
        .post(`/schedulenotification/?country=${pais}`, notificacao, config)
        .then((resp) => {
          getNotifications();
          swal("Sucesso!", "Notificação agendada!", "success");
          console.log("Resposta server: ", resp.data);
        })
        .catch((error) => {
          console.log("Error server: ", error);
        })
        .finally(() => {
          clear();
          setLoading(false);
        });
    }
    await api
      .post(`/pushnotification/?country=${pais}`, notificacao, config)
      .then((resp) => {
        swal("Sucesso!", "Notificação enviada!", "success");
        console.log("Resposta server: ", resp.data);
      })
      .catch((error) => {
        console.log("Error server: ", error);
      })
      .finally(() => {
        clear();
        setLoading(false);
      });
  };

  const getAnnouncements = async (text) => {
    try {
      const resp = await api.get(`/announcement/?search=${text}`, { headers: { Authorization: `Bearer ${token}` } });
      setAnnouncements(resp.data);
      const result = [];
      for (let i = 0; i < resp.data.length; i++) {
        const each = resp.data[i];
        result.push({ value: each._id, label: each.title });
      }
      return result;
    } catch (error) {
      console.log("getAnnouncements", error);
    }
  };
  //! Função abaixo para testar notificações
  // const notificationMe = async () => {
  //   let notificacao = {
  //     title: titulo,
  //     msg: mensagem,
  //     to: "ExponentPushToken[eN41zsMuSnmhYRzAHlxyWH]",
  //     url: { page: "Single Ad", id: link },
  //   };
  //   try {
  //     const resp = await api.post(`/notifications-for-me/`, notificacao, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     console.log(resp.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getNotifications();
    getAnnouncements();
  }, [filter]);

  const clear = () => {
    setTitulo("");
    setMensagem("");
    setPais("");
    setToggle(false);
    setDate("");
    setTime("");
  };

  const getNotifications = async () => {
    setLoadingNotifications(true);
    try {
      await api.get(`/notifications/?sent=${filter}`).then((resp) => {
        setNotifications(() => resp.data);
      });
    } catch (error) {
      console.log("getNotifications error", error.message);
    } finally {
      setLoadingNotifications(false);
    }
  };
  return (
    <>
      <div className="content">
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Enviar notificação</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>Notificação Agendada</label>
                        <Toggle
                          checked={toggle}
                          text="Agendar"
                          name="schedule"
                          size={"default"}
                          disabled={false}
                          onChange={(e) => {
                            setToggle(!toggle);
                          }}
                          offstyle="btn-danger"
                          onstyle="btn-success"
                        />
                      </FormGroup>
                    </Col>
                    {toggle ? (
                      <>
                        <Col md="3">
                          <FormGroup>
                            <label>Data do agendamento</label>

                            <input
                              className="w-100 form-control"
                              type="date"
                              placeholder=""
                              onChange={(e) => setDate(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label>Hora do agendamento</label>

                            <select
                              className="form-control"
                              style={{ width: "90px" }}
                              onChange={(e) => setTime(e.target.value)}
                            >
                              {hoursList.map((hour) => (
                                <option key={hour.id} value={hour.value}>
                                  {hour.value}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col md="12">
                      <FormGroup>
                        <label>Título</label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          placeholder=""
                          value={titulo}
                          onChange={(e) => setTitulo(() => e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label>Mensagem</label>
                        <textarea
                          rows="6"
                          className="w-100 form-control"
                          type="text"
                          placeholder=""
                          value={mensagem}
                          onChange={(e) => setMensagem(() => e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label>País</label>
                        <select
                          className="w-100 form-control"
                          defaultValue={""}
                          type="text"
                          required
                          onChange={(e) => setPais(() => e.target.value)}
                        >
                          <option value="">Todos os paises</option>
                          <option value="UK">Inglaterra</option>
                          <option value="Brazil">Brasil</option>
                          <option value="France">França</option>
                          <option value="Italy">Italia</option>
                          <option value="Spain">Espanha</option>
                          <option value="United Arab Emirates">Emirados Arabes</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label>Link Anúncio</label>
                        <AsyncSelect
                          defaultOptions
                          cacheOptions
                          loadOptions={getAnnouncements}
                          onChange={(e) => setLink(e.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button block color="primary" onClick={() => sendNotification()}>
                        {isLoading ? (
                          <Spinner size="sm" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : toggle ? (
                          "Salvar"
                        ) : (
                          "Enviar"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Col md="12">
                  <Nav>
                    <NavItem>
                      <NavLink
                        style={{ color: filter === false ? "#ef8157" : "" }}
                        href="#"
                        onClick={() => setFilter(false)}
                      >
                        Não enviadas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" style={{ color: filter ? "#ef8157" : "" }} onClick={() => setFilter(true)}>
                        Enviadas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" style={{ color: filter === "" ? "#ef8157" : "" }} onClick={() => setFilter("")}>
                        Todas
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center col-lg-2">Agendada</th>
                        <th className="text-center col-lg-3">Titulo</th>
                        <th className="text-center col-lg-5">Mensagem</th>
                        <th className="text-center col-lg-1">Enviada</th>
                        <th className="text-center">Excluir</th>
                      </tr>
                    </thead>
                    {loadingNotifications ? <></> : <ListNotifications {...{ notifications, getNotifications }} />}
                  </Table>
                  {loadingNotifications ? (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <Spinner size="md" animation="border" role="status" style={{ color: "#51cbce" }}>
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Notification;
